var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 animate__animated animate__fadeIn",staticStyle:{"width":"100%"}},[(_vm.pendingShopSales)?_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-alert',{attrs:{"dense":"","type":"success"}},[_c('span',{staticClass:" ft font-weight-medium font-size-md"},[_vm._v("Reviewing And Approval Of Shop Sales")])]),(
        _vm.endOfDaySales.salesAmount < _vm.totalAmountTallied ||
          _vm.endOfDaySales.mdsAllocationAmount <
            _vm.sum(
              _vm.endOfDaySales.cashPayments.filter(
                function (sale) { return sale.isPaymentMadeFromSalesAmount === 'mds-allocation'; }
              ),
              'amount'
            ) +
              _vm.sum(
                _vm.endOfDaySales.cashPurchases.filter(
                  function (sale) { return sale.isPaymentMadeFromSalesAmount === 'mds-allocation'; }
                ),
                'amount'
              )
      )?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(" Total amount of sales should be balanced ")])]):_vm._e()],1):_vm._e(),(_vm.pendingShopSales)?_c('ValidationObserver',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('form',{staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmationAlert)}}},[[_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-4"},[_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-md pa-1",attrs:{"outlined":"","prepend-icon":"shopping_basket","error-messages":errors[0],"hint":"Total end of sales amount","prefix":_vm.currency && _vm.currency.length > 0 ? _vm.currency[0].symbol : 'GHS',"persistent-hint":"","label":"Total Sales Amount"},model:{value:(_vm.endOfDaySales.salesAmount),callback:function ($$v) {_vm.$set(_vm.endOfDaySales, "salesAmount", $$v)},expression:"endOfDaySales.salesAmount"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-md pa-1",attrs:{"outlined":"","readonly":"","prepend-icon":"credit_card","error-messages":errors[0],"hint":"MDs total amount added","prefix":_vm.currency && _vm.currency.length > 0 ? _vm.currency[0].symbol : 'GHS',"persistent-hint":"","label":"MDs Allocation Amount"},model:{value:(_vm.endOfDaySales.mdsAllocationAmount),callback:function ($$v) {_vm.$set(_vm.endOfDaySales, "mdsAllocationAmount", $$v)},expression:"endOfDaySales.mdsAllocationAmount"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-md pa-1",attrs:{"outlined":"","disabled":"","error-messages":errors[0],"prefix":_vm.currency && _vm.currency.length > 0 ? _vm.currency[0].symbol : 'GHS',"label":"Total Amount Left In Hand","hint":"Cash to bank after sales tally","persistent-hint":""},model:{value:(_vm.endOfDaySales.cashDeposit),callback:function ($$v) {_vm.$set(_vm.endOfDaySales, "cashDeposit", $$v)},expression:"endOfDaySales.cashDeposit"}})]}}],null,true)})],1),_c('RecordCashPurchases',{attrs:{"date":_vm.date,"totalSalesAmount":isNaN(parseFloat(_vm.endOfDaySales.salesAmount))
              ? 0
              : parseFloat(_vm.endOfDaySales.salesAmount),"totalAmountTallied":_vm.totalAmountTallied,"cashPurchases":_vm.endOfDaySales.cashPurchases,"currency":_vm.currency,"suppliers":_vm.suppliers,"salesPaymentOptions":_vm.salesPaymentOptions},on:{"addCashPurchase":_vm.addCashPurchase}}),_c('RecordCashPayments',{attrs:{"date":_vm.date,"hasMDsAllocationTallied":_vm.endOfDaySales.mdsAllocationAmount <=
              _vm.balancingAmount(
                _vm.endOfDaySales.cashPayments,
                _vm.endOfDaySales.cashPurchases,
                'mds-allocation'
              ),"totalSalesAmount":isNaN(parseFloat(_vm.endOfDaySales.salesAmount))
              ? 0
              : parseFloat(_vm.endOfDaySales.salesAmount),"totalAmountTallied":_vm.totalAmountTallied,"cashPayments":_vm.endOfDaySales.cashPayments,"currency":_vm.currency,"suppliers":_vm.suppliers,"salesPaymentOptions":_vm.salesPaymentOptions},on:{"addCashPayment":_vm.addCashPayment}}),_c('RecordCreditPurchases',{attrs:{"date":_vm.date,"creditPurchases":_vm.endOfDaySales.creditPurchases,"currency":_vm.currency,"suppliers":_vm.suppliers},on:{"addCreditPurchase":_vm.addCreditPurchase}})]],2)]}}],null,false,1795627993)}):_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center"},[_c('EmptyPage',{attrs:{"image":require("@/assets/folder.svg"),"title":'No Pending Shop Sales',"imageWidth":'100px',"subTitle":'Proceed to lube sales'}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }