<template>
  <div
    class="d-flex flex-column flex-grow-1 animate__animated animate__fadeIn"
    style="width: 100%;"
  >
    <div v-if="pendingShopSales" class="d-flex flex-column flex-grow-1">
      <v-alert dense type="success">
        <span class=" ft font-weight-medium font-size-md"
          >Reviewing And Approval Of Shop Sales</span
        >
      </v-alert>
      <v-alert
        type="error"
        dense
        v-if="
          endOfDaySales.salesAmount < totalAmountTallied ||
            endOfDaySales.mdsAllocationAmount <
              sum(
                endOfDaySales.cashPayments.filter(
                  sale => sale.isPaymentMadeFromSalesAmount === 'mds-allocation'
                ),
                'amount'
              ) +
                sum(
                  endOfDaySales.cashPurchases.filter(
                    sale =>
                      sale.isPaymentMadeFromSalesAmount === 'mds-allocation'
                  ),
                  'amount'
                )
        "
      >
        <span class="font font-weight-medium font-size-sm">
          Total amount of sales should be balanced
        </span>
      </v-alert>
    </div>
    <ValidationObserver
      v-if="pendingShopSales"
      tag="div"
      class="d-flex flex-row flex-grow-1"
      v-slot="{ handleSubmit }"
    >
      <form
        style="width: 100%;"
        @submit.prevent="handleSubmit(confirmationAlert)"
      >
        <template>
          <div class="d-flex flex-row flex-grow-1 mt-4">
            <ValidationProvider
              tag="div"
              class="d-flex flex-column flex-grow-1"
              rules="required|decimals"
              v-slot="{ errors }"
            >
              <v-text-field
                outlined
                prepend-icon="shopping_basket"
                v-model="endOfDaySales.salesAmount"
                :error-messages="errors[0]"
                hint="Total end of sales amount"
                :prefix="
                  currency && currency.length > 0 ? currency[0].symbol : 'GHS'
                "
                persistent-hint
                label="Total Sales Amount"
                class=" ft font-weight-medium font-size-md pa-1"
              />
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              class="d-flex flex-column flex-grow-1"
              rules="required|decimals"
              v-slot="{ errors }"
            >
              <v-text-field
                outlined
                readonly
                prepend-icon="credit_card"
                v-model="endOfDaySales.mdsAllocationAmount"
                :error-messages="errors[0]"
                hint="MDs total amount added"
                :prefix="
                  currency && currency.length > 0 ? currency[0].symbol : 'GHS'
                "
                persistent-hint
                label="MDs Allocation Amount"
                class=" ft font-weight-medium font-size-md pa-1"
              />
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              class="d-flex flex-column flex-grow-1"
              rules="required|decimals"
              v-slot="{ errors }"
            >
              <v-text-field
                outlined
                disabled
                v-model="endOfDaySales.cashDeposit"
                :error-messages="errors[0]"
                :prefix="
                  currency && currency.length > 0 ? currency[0].symbol : 'GHS'
                "
                label="Total Amount Left In Hand"
                hint="Cash to bank after sales tally"
                persistent-hint
                class=" ft font-weight-medium font-size-md pa-1"
              />
            </ValidationProvider>
          </div>
          <RecordCashPurchases
            :date="date"
            @addCashPurchase="addCashPurchase"
            :totalSalesAmount="
              isNaN(parseFloat(endOfDaySales.salesAmount))
                ? 0
                : parseFloat(endOfDaySales.salesAmount)
            "
            :totalAmountTallied="totalAmountTallied"
            :cashPurchases="endOfDaySales.cashPurchases"
            :currency="currency"
            :suppliers="suppliers"
            :salesPaymentOptions="salesPaymentOptions"
          />
          <RecordCashPayments
            :date="date"
            :hasMDsAllocationTallied="
              endOfDaySales.mdsAllocationAmount <=
                balancingAmount(
                  endOfDaySales.cashPayments,
                  endOfDaySales.cashPurchases,
                  'mds-allocation'
                )
            "
            :totalSalesAmount="
              isNaN(parseFloat(endOfDaySales.salesAmount))
                ? 0
                : parseFloat(endOfDaySales.salesAmount)
            "
            :totalAmountTallied="totalAmountTallied"
            @addCashPayment="addCashPayment"
            :cashPayments="endOfDaySales.cashPayments"
            :currency="currency"
            :suppliers="suppliers"
            :salesPaymentOptions="salesPaymentOptions"
          />
          <RecordCreditPurchases
            :date="date"
            :creditPurchases="endOfDaySales.creditPurchases"
            :currency="currency"
            :suppliers="suppliers"
            @addCreditPurchase="addCreditPurchase"
          />
        </template>
      </form>
    </ValidationObserver>
    <div v-else class="d-flex flex-row flex-grow-1 justify-center">
      <EmptyPage
        :image="require(`@/assets/folder.svg`)"
        :title="'No Pending Shop Sales'"
        :imageWidth="'100px'"
        :subTitle="'Proceed to lube sales'"
      />
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { decimals, roundAccurately } from "../../../utils/resolvers";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import WidgetMixins from "../../../../mixins/WidgetMixins";
import { groupBy } from "lodash";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("decimals", decimals);
export default {
  name: "PendingShopSalesComponent",
  components: {
    ValidationProvider,
    ValidationObserver,
    EmptyPage: () => import(`../../../../components/EmptyPage`),
    RecordCashPurchases: () => import(`../RecordCashPurchases`),
    RecordCashPayments: () => import(`../RecordCashPayments`),
    RecordCreditPurchases: () => import(`../RecordCreditPurchases`)
  },
  computed: {
    ...mapGetters({
      allocationAmount: "prices/getAllocationAmount",
      pendingShopSales: "sales/getAllPendingShopSales",
      suppliers: "suppliers/getListOfSuppliers"
    })
  },
  mixins: [WidgetMixins],
  props: {
    currency: {
      type: Array,
      default: () => []
    },
    date: {
      type: String,
      default: null
    },
    branchId: {
      type: String,
      defaut: null
    }
  },
  data: () => ({
    endOfDaySales: {
      saleId: "",
      salesAmount: "",
      cashDeposit: "",
      mdsAllocationAmount: "",
      cashPurchases: [],
      cashPayments: [], // also called credit payment
      creditPurchases: []
    },
    totalAmountTallied: 0,
    temp: {
      cashDeposit: 0,
      mdsAllocation: 0
    },
    hasSalesTallied: false,
    salesPaymentOptions: []
  }),
  watch: {
    pendingShopSales(payload) {
      const {
        id,
        salesAmount,
        cashDeposit,
        mdsAllocationAmount,
        supplierSalesRecords
      } = payload;
      this.temp = {
        cashDeposit,
        mdsAllocation: mdsAllocationAmount
      };
      this.endOfDaySales = {
        saleId: id,
        salesAmount,
        cashDeposit,
        mdsAllocationAmount,
        cashPurchases: [
          ...supplierSalesRecords.map(record => record.cashPurchases)
        ].reduce((acc, item) => acc.concat(item), []),
        creditPurchases: [
          ...supplierSalesRecords.map(record => record.creditPurchases)
        ].reduce((acc, item) => acc.concat(item), []),
        cashPayments: [
          ...supplierSalesRecords.map(record => record.cashPayments)
        ].reduce((acc, item) => acc.concat(item), [])
      };
    },
    "endOfDaySales.cashPurchases": {
      handler(payload) {
        const amountTallied = this.balancingAmount(
          payload,
          this.endOfDaySales.cashPayments,
          "sales-amount"
        );
        this.totalAmountTallied = amountTallied;
        this.endOfDaySales.cashDeposit =
          this.endOfDaySales.salesAmount - amountTallied;
      },
      deep: true
    },
    "endOfDaySales.cashPayments": {
      handler(payload) {
        const amountTallied = this.balancingAmount(
          payload,
          this.endOfDaySales.cashPurchases,
          "sales-amount"
        );

        this.totalAmountTallied = amountTallied;
        this.endOfDaySales.cashDeposit =
          this.endOfDaySales.salesAmount - amountTallied;
      },
      deep: true
    },
    "endOfDaySales.salesAmount": debounce(function(value) {
      const amount = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      this.endOfDaySales.cashDeposit = roundAccurately(
        amount - this.totalAmountTallied,
        2
      );
      this.temp.cashDeposit = roundAccurately(
        amount - this.totalAmountTallied,
        2
      );
      this.salesPaymentOptions = [
        ...this.salesPaymentOptions.filter(sale => sale?.id !== "sales-amount"),
        ...[amount > 0 && { id: "sales-amount", value: "Total Sales Amount" }]
      ].filter(sale => sale);
    }, 500),
    allocationAmount: debounce(function(value) {
      const amount = isNaN(parseFloat(value?.totalAmount))
        ? 0
        : parseFloat(value?.totalAmount);
      this.endOfDaySales.mdsAllocationAmount = roundAccurately(amount, 2);
      this.temp.mdsAllocation = roundAccurately(amount, 2);
      this.salesPaymentOptions = [
        ...this.salesPaymentOptions.filter(
          sale => sale?.id !== "mds-allocation"
        ),
        ...[amount > 0 && { id: "mds-allocation", value: "MD's Allocation" }]
      ].filter(sale => sale);
    }, 500)
  },
  methods: {
    addCreditPurchase(payload) {
      this.endOfDaySales.creditPurchases.push(payload);
    },
    addCashPurchase(payload) {
      this.endOfDaySales.cashPurchases.push(payload);
    },
    addCashPayment(payload) {
      this.endOfDaySales.cashPayments.push(payload);
    },
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    balancingAmount(
      saleOnChange,
      saleOnStale,
      lookup = "sales-amount",
      key = "amount"
    ) {
      return (
        this.sum(
          saleOnChange.filter(
            sale => sale.isPaymentMadeFromSalesAmount === lookup
          ),
          key
        ) +
        this.sum(
          saleOnStale.filter(
            sale => sale.isPaymentMadeFromSalesAmount === lookup
          ),
          key
        )
      );
    },
    confirmationAlert() {
      this.recordDailySales();
    },
    recordDailySales() {
      const { creditPurchases, salesAmount, cashDeposit } = this.endOfDaySales;

      const cashPurchases = this.endOfDaySales.cashPurchases.map(sale => {
        return {
          supplierId: sale.supplierId?.id
            ? sale.supplierId?.id
            : sale.supplierId,
          name: sale.supplierId?.name ? sale.supplierId?.name : sale?.name,
          slug: sale.supplierId?.slug ? sale.supplierId?.slug : sale?.slug,
          type: sale.type,
          amount: Number(sale.amount),
          invoiceNumber: sale?.invoiceNumber,
          isPaymentMadeFromSalesAmount: sale.isPaymentMadeFromSalesAmount
        };
      });
      const cashPayments = this.endOfDaySales.cashPayments.map(sale => {
        return {
          supplierId: sale.supplierId?.id
            ? sale.supplierId?.id
            : sale.supplierId,
          name: sale.supplierId?.name ? sale.supplierId?.name : sale?.name,
          slug: sale.supplierId?.slug ? sale.supplierId?.slug : sale?.slug,
          invoiceNumber: sale?.invoiceNumber,
          type: sale.type,
          amount: Number(sale.amount),
          isPaymentMadeFromSalesAmount: sale.isPaymentMadeFromSalesAmount
        };
      });
      const purchasesByCredit = Object.entries(
        groupBy(creditPurchases, "supplierId")
      ).map(([supplierId, sales]) => {
        return {
          supplierId: Number(supplierId),
          amount: this.sum(sales, "amount"),
          invoiceNumber: sales
            .map(({ invoiceNumber }) => invoiceNumber)
            ?.join(","),
          type: "credit-purchase"
        };
      });

      let results = {
        ...this.endOfDaySales,
        isError:
          this.endOfDaySales.salesAmount < this.totalAmountTallied ||
          this.endOfDaySales.mdsAllocationAmount <
            this.sum(
              this.endOfDaySales.cashPayments.filter(
                sale => sale.isPaymentMadeFromSalesAmount === "mds-allocation"
              ),
              "amount"
            ) +
              this.sum(
                this.endOfDaySales.cashPurchases.filter(
                  sale => sale.isPaymentMadeFromSalesAmount === "mds-allocation"
                ),
                "amount"
              ),
        salesAmount: Number(salesAmount),
        cashDeposit: Number(cashDeposit),
        mdsAllocationAmount: roundAccurately(
          [...cashPurchases, ...cashPayments]
            .filter(
              record =>
                record.isPaymentMadeFromSalesAmount &&
                record.isPaymentMadeFromSalesAmount === "mds-allocation"
            )
            .reduce((acc, sale) => (acc += Number(sale.amount)), 0) ?? 0,
          2
        ),
        salesDate: this.date,
        cashPayments,
        cashPurchases,
        creditPurchases: purchasesByCredit
      };

      let finalResults = {
        ...results,
        cashDeposit: roundAccurately(results.cashDeposit, 2) ?? 0,
        branchId: this.branchId
      };
      this.$store.dispatch("sales/approvalOfDailyShopSales", finalResults);
    }
  },
  created() {}
};
</script>
